/**
 *
 * @cass
 */
var AircraftFormTable = (function () {

    var _private = {
        cloneWithDataAndEvents: true,
        table:                  $()
    };

    _private.instance = this;

    /**
     *
     * @param {*} formTable
     * @param {boolean} [cloneWithDataAndEvents]
     */
    this.init = function (formTable, cloneWithDataAndEvents = false) {
        if (!formTable.hasClass('form-table')) {
            formTable.find('.form-table').each(function (i, table) {
                new AircraftFormTable().init($(table), cloneWithDataAndEvents);
            });
            return;
        }
        _private.table = formTable;
        _private.table.data('AircraftFormTable', this);
        _private.cloneWithDataAndEvents = _.isUndefined(cloneWithDataAndEvents) ? true : !!cloneWithDataAndEvents;
        _private.bindEvents();
    };

    /**
     *
     * @param {*} formTable
     */
    this.bindFormTable = function (formTable) {
        formTable = $(formTable);
        formTable.find('div.add-row').find('span').unbind('click.addLine').on('click.addLine',
            function (event) {
                _private.instance.addLine($(event.target).parents('.form-table-line'));
            }
        );
        formTable.find('div.remove-row').find('span').unbind('click.removeLine').on('click.removeLine',
            function (event) {
                _private.instance.removeLine($(event.target).parents('.form-table-line'));
            }
        );
        if (!formTable.hasClass('required')) {
            formTable.find('input[type=text]').unbind('blurEnter.checkOptional').on('blurEnter.checkOptional', function (event) {
                _private.checkOptionalTable($(event.target).parents('.form-table-line'));
            }).first().blur();
        }
        _private.initSortable(formTable);
    };

    /**
     *
     */
    _private.bindEvents = function () {
        _private.initUniqueValidation(_private.table);
        if (_private.table.hasClass('tank-list')) {
            _private.initTankValidation();
        }
        _private.instance.bindFormTable(_private.table);
    };

    /**
     *
     * @param {*} formTable
     */
    _private.initSortable = function (formTable) {
        if (!formTable.hasClass('sortable')) {
            return;
        }
        formTable.sortable({
            items:  '.form-table-line',
            axis:   'y',
            handle: '.sort-handle',
            stop:   function (event, ui) {
                _private.reorderList($(event.target));
                ui.item.find('input[type=text]').blur();
            }
        });
    };

    _private.checkOptionalTable = function (line) {
        var list                = line.parent('.form-table');
        var disableRequirements = function (line) {
            line.find('input,select').each(function (i, el) {
                el       = $(el);
                var type = el.attr('type');
                var name = el.attr('name');
                if (name.indexOf('optional_') !== -1) {
                    return;
                }
                el.attr('name', 'optional_' + name);
                if ($.inArray(type, ['hidden', 'checkbox']) !== -1) {
                    return;
                }
                var reqO = el.attr('original-validate-required');
                var req  = el.attr('validate-required');
                if (!el.hasClass('indicator')) {
                    el.pVal(0).val('').prop('disabled', true);
                }
                if (!_.isUndefined(reqO) || _.isUndefined(req)) {
                    return;
                }
                el.attr('original-validate-required', req);
                el.attr('validate-required', 0);
                _private.getObserver().removeElements(line, false, false);
                Validator.validateElement(el);
            });
            list.trigger('formTableElement', [line])
                .trigger('formTableElementDisabled', [line]);
        };
        var enableRequirements  = function (line) {
            line.find('input,select').each(function (i, el) {
                el       = $(el);
                var type = el.attr('type');
                el.attr('name', el.attr('name').replace('optional_', ''));
                if ($.inArray(type, ['hidden', 'checkbox']) !== -1) {
                    return;
                }
                if (!el.hasClass('indicator')) {
                    el.prop('disabled', false);
                }
                var req = el.attr('original-validate-required');
                if (_.isUndefined(req)) {
                    return;
                }
                el.attr('validate-required', req);
                el.removeAttr('original-validate-required');
                _private.getObserver().addElement(line);
                Validator.validateElement(el);
            });
            list.trigger('formTableElement', [line])
                .trigger('formTableElementEnabled', [line]);
        };
        var isFilled            = function (line) {
            var val = '';
            line.find('input.indicator[type=text],select.indicator').each(function (i, el) {
                val += $(el).val();
            });
            return $.trim(val) !== '';
        };
        if (list.find('.form-table-line').length > 1 || isFilled(line)) {
            enableRequirements(line);
            return true;
        }
        disableRequirements(line);
        return false;
    };

    /**
     *
     * @param {*} formTable
     */
    _private.initUniqueValidation = function (formTable) {
        $(formTable).find('.form-table-line').not('.inactive').find('input[name*="[seat_id]"]')
                    .unbind('blurEnter.validateUniqueItem').on('blurEnter.validateUniqueItem',
            function (event) {
                _private.updateLabelPlaceHolder($(event.target));
                _private.validateUniqueItem(event.target, 'seat_id');
            }
        );
    };

    /**
     *
     */
    _private.initTankValidation = function () {
        _private.table.find('.form-table-line').not('.inactive').find('input[name*="[tank_id]"]')
                .unbind('blurEnter.validateUniqueItem').on('blurEnter.validateUniqueItem',
            function (event) {
                _private.validateUniqueItem(event.target, 'tank_id');
            }
        );
    };

    _private.updateLabelPlaceHolder = function (seatIdElement) {
        var line = seatIdElement.parents('div.form-table-line');
        line.find('input[name*="[label]"]').attr('placeholder', seatIdElement.val());
    };

    /**
     *
     * @param {*} input
     * @param {string} fieldName
     */
    _private.validateUniqueItem = function (input, fieldName) {
        var list    = $(input).parents('.form-table');
        var isValid = true;
        var seatSet = {};
        list.find('input[name*=' + fieldName + ']').each(function (i, inp) {
            inp = $(inp);
            if (inp.hasClass('inactive')) {
                return;
            }
            var value = inp.val();
            if (!_.isUndefined(seatSet[fieldName + '_' + value])) {
                Validator.addError(inp, Validator.NsNotUnique);
                isValid = false;
                return;
            }
            seatSet[fieldName + '_' + value] = true;
            Validator.removeError(inp, Validator.NsNotUnique);
        });
    };

    /**
     *
     * @param {*} line
     * @param {function} [callBack]
     */
    this.addLine = function (line, callBack) {
        line          = !line ? _private.table.find('.form-table-line').last() : line;
        var lineClone = line.clone(_private.cloneWithDataAndEvents);
        var formTable = line.parents('.form-table');
        var form      = formTable.parents('form');
        if (lineClone.find('input[name*=uuid]').length) {
            lineClone.find('input[name*=uuid]').val(EfbHelper.generateUUID())
        }
        lineClone.find('input[type=text]').each(function (i, inp) {
            $(inp).pVal(0).val('');
        });
        lineClone.find('select').val('');
        lineClone.find('input[type=checkbox][name*=installed]').prop('checked', true);
        lineClone.find('input[type=checkbox][name*=weighted]').prop('checked', false);
        lineClone.removeClass('weighted');
        lineClone.find('input,select').each(function (i, inp) {
            inp      = $(inp);
            var id   = inp.attr('id');
            var name = inp.attr('name');
            inp.attr('readonly', false);
            inp.attr('disabled', false);
            if (!_.isUndefined(id)) {
                inp.attr('id', id.replace('expand_', ''));
            }
            if (!_.isUndefined(name)) {
                inp.attr('name', name.replace('expand_', ''));
            }
        });
        lineClone.find('input[name*=moment]').attr('readonly', true);
        line.find('label').each(function (i, label) {
            $(label).attr('for', $(label).attr('for').replace('expand_', ''));
        });

        lineClone.insertAfter(line);
        _private.reorderList(formTable);
        if (formTable.hasClass('equipment-list')) {
            new AircraftMassAndBalance().init(form);
        } else if (formTable.hasClass('seat-list')) {
            _private.initUniqueValidation(formTable);
        } else if (formTable.hasClass('tank-list')) {
            _private.initTankValidation(formTable);
        }
        new FormObserver(form).addElement(lineClone.find('input[id],select'));
        _private.bindEvents(formTable);
        lineClone.find('input[type=text]').blur();
        lineClone.find('select').change();
        lineClone.find('input[type=text]:first').focus();
        if (!formTable.hasClass('required')) {
            _private.checkOptionalTable(line);
        }
        if (_.isFunction(callBack) && !callBack(lineClone)) {
            return;
        }
        line.parents('.form-table').trigger('formTableElement').trigger('formTableElementAdd', [lineClone]);
        _private.getObserver().addElement(lineClone);

    };

    /**
     *
     * @param {*} line
     */
    this.removeLine = function (line) {
        var list       = line.parents('.form-table');
        var isRequired = list.hasClass('required');
        if (line.hasClass('inactive')) {
            return;
        }
        if (list.find('.form-table-line').length < 2) {
            if (!isRequired) {
                line.find('input.indicator').val('');
                _private.checkOptionalTable(line);
            }
            return;
        }
        line.find('input,select').each(function (i, inp) {
            new FormObserver(line.parents('form')).removeElement(inp);
        });
        _private.getObserver().removeElements(line, false, false);
        line.remove();
        _private.reorderList(list);
        if (!isRequired) {
            _private.checkOptionalTable(list.find('.form-table-line').first());
        }
        list.find('div.form-table-line').first().find('input[type=text]').blur();
        list.trigger('formTableElement').trigger('formTableElementRemove', [line]);
    };

    /**
     *
     * @param {*} list
     */
    _private.reorderList = function (list) {
        list.find('.form-table-line').each(function (i, line) {
            line = $(line);
            line.find('input,select').each(function (l, inp) {
                inp      = $(inp);
                var id   = inp.attr('id');
                var name = inp.attr('name');
                if (!_.isUndefined(id)) {
                    inp.attr('id', id.replace(/\d+/, i));
                }
                if (!_.isUndefined(name)) {
                    inp.attr('name', name.replace(/\d+/, i));
                }
            });
            line.find('label').each(function (l, label) {
                label            = $(label);
                var forAttribute = label.attr('for');
                if (!_.isUndefined(forAttribute)) {
                    label.attr('for', forAttribute.replace(/\d+/, i));
                }
            });
        });
    };

    /**
     *
     * @returns {FormObserver}
     */
    _private.getObserver = function () {
        if (_private.observer) {
            return _private.observer;
        }
        var form = _private.table.parents('form');
        form     = form.length ? form : _private.table.parent('form');
        if (!form.length || !form.data('FormObserver')) {
            return new FormObserver($('<form/>'));
        } else {
            _private.observer = form.data('FormObserver');
        }
        return _private.observer;
    };
});
